<template>
  <div>
    <base-material-card
      title="项目动态"
      icon="mdi-clipboard-text-clock"
      class="mt-12 pr-0"
    >
      <template
        v-slot:after-heading2
      >
        <div style="position:absolute;right:0px">
          <v-btn
            color="#0D47A1"
            small
            @click="jump"
          >
            更多
          </v-btn>
        </div>
      </template>

      <v-container>
        <div class="line_box">
          <v-row
            v-for="(item, inx) in projectList"
            :key="inx"
          >
            <v-col cols="5">
              <v-chip
                class="white--text ml-0"
                :color="runColor(item.data.stageStatus)"
                label
                small
              >
                {{ item.data.stageStatus }}
              </v-chip>
              <span class="ml-1"> {{ item.data.park }}</span>
            </v-col>
            <v-col
              cols="6"
              class="text-right "
            >
              {{ item.data.createdAt | dateformat1 }}
            </v-col>
            <v-col cols="11">
              <v-card
                class="mx-auto pa-5 mt-0"
                outlined
              >
                <p
                  class="ma-0"
                >
                  {{ item.data.content }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </base-material-card>
  </div>
</template>

<script>
  import userPhoto from '../../../assets/user_photo.jpg'
  export default {
    props: {
      reportId: {
        type: String,
        default: null,
      },
      formId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        logs: [],
        userPhoto: userPhoto,
        searchParame: {
          page: 0,
          size: 5,
          formId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
        },
        projectList: [],
        orgQX: false,
      }
    },
    created () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      this.getProjectList()
    },
    methods: {
      open () {
        this.getProjectList()
      },
      async getProjectList () { // 获取项目列表
        const searchParame = this.searchParame
        if (!this.orgQX) {
          searchParame._from = 'public'
        }
        searchParame.parentID = this.reportId
        await this.$axios.get('/reports/query', {
          params: searchParame, // 搜索参数对象
        })
          .then((response) => {
            const resData = response.data.data
            console.log('response', response);

            this.projectList = resData.content
          })
          .catch((error) => {
            console.log(error)
          })
      },
      jump () {
        this.$router.push({
          name: 'DynamicDetails',
          params: { formId: this.formId, id: this.reportId },
        })
      },
      runColor (key) {
        let color = '#ACB4C7'
        switch (key) {
          case '在谈':
            color = '#20c954'
            break;
          case '落地':
            color = '#01579B'
            break;
          case '签约':
            color = '#FF9900'
            break;
          case '中止':
            color = 'red'
            break;
          case '对接':
            color = '#20c954'
            break;
          default:
            break;
        }
        return color
      },
    },
  }
</script>

<style lang="scss" scoped>
.line_box {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
